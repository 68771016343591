import React, { FC } from 'react';
import { marked } from 'marked';

import { Content, Wrapper } from './styles';
import { ContentfulSection } from '../types.d';
import { ContentfulMarkdown } from '@/types';

export interface HeadlineSection extends ContentfulSection {
  content: ContentfulMarkdown;
  title: string;
}

/**
 * Headline section
 */
const Headline: FC<HeadlineSection> = ({ content, title }) => (
  <Wrapper>
    {!!title && <h2>{title}</h2>}
    {!!content && (
      <Content dangerouslySetInnerHTML={{ __html: marked(content.content) }} />
    )}
  </Wrapper>
);

export default Headline;
