import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { desktopDown, phabletAndBelow } from '@/app/common/style/mixins';
import { h3 } from '@/app/common/style/typography';
// import { rem } from '@/app/common/style/functions';

export const Wrapper = styled.div`
  padding: 56px 160px 60px 160px;

  > h2 {
    text-align: center;
  }

  ${desktopDown(css`
    padding: 40px 0 40px 0;
  `)}

  ${phabletAndBelow(css`
    padding: 40px 0 20px 0;

    > h2 {
      text-align: left;
      margin-bottom: 20px;
    }
  `)}
`;

export const Content = styled.div`
  > p {
    ${css(h3)}
  }
`;
